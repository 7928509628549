import "../styles/signup-dfy.scss"
import React, { useState, useEffect } from "react"
import { useForm } from "react-hook-form"
import toast from "react-hot-toast"
import { loadStripe } from "@stripe/stripe-js"
// import { useFirebaseArsenal, useAuth } from "../lib/firebase/firebaseCentral"
import { Link, navigate, StaticQuery, graphql } from "gatsby"
// import Img from "gatsby-image"
import GoTrue from "gotrue-js"
import firebase from "gatsby-plugin-firebase"
import { StaticImage } from "gatsby-plugin-image"
import { Helmet } from "react-helmet"
// import favicon from "../images/favicon.ico"
import favicon from "../images/favicon.png"

import {
  CardElement,
  //   CardNumberElement,
  //   CardExpiryElement,
  //   CardCvcElement,
  Elements,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js"
// import Header from "../components/TealHeader"
import Header from "../components/Header"

const StripeCard = ({
  name,
  email,
  password,
  firebase,
  identity,
  setPaymentComplete,
}) => {
  const stripe = useStripe()
  const elements = useElements()

  const [error, setError] = useState(null)
  const [subscriptions, setSubscriptions] = useState([])

  const [processing, setProcessing] = useState(false)
  const [paymentMethod, setPaymentMethod] = useState(null)
  // useEffect(() => {
  //   document.documentElement.style.setProperty(
  //     "--scrollbar-width",
  //     window.innerWidth - document.documentElement.clientWidth + "px"
  //   )
  // }, [])
  const getSubscriptions = async () => {
    if (email && firebase) {
      //   const subs = await firebase.listSubscriptions({ userId: email })
      const listSubscriptionsCallable = await firebase
        .functions()
        .httpsCallable("listSubscriptions")
      const subs = await listSubscriptionsCallable({
        userId: email,
      })
      // console.log("subs", subs);
      setSubscriptions(subs.data.data)
    }
  }

  // console.log("email", email);
  // Cancel subscription
  const cancelSubscription = async id => {
    setProcessing(true)
    const cancelSubscriptionCallable = await firebase
      .functions()
      .httpsCallable("cancelSubscription")
    await cancelSubscriptionCallable({
      userId: email,
      subscriptionId: id,
    })
    // await firebase.cancelSubscription({
    //   userId: email,
    //   subscriptionId: id,
    // })
    toast.success("Your previous subscription has been canceled!")
    await getSubscriptions() // rehydrate subscriptions
    setProcessing(false)
  }

  const handleSubmit = async event => {
    // event.preventDefault()

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return
    }

    if (error) {
      elements.getElement("card").focus()
      return
    }

    // if (cardComplete) {
    //   setProcessing(true)
    // }
    setProcessing(true)

    const payload = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement),
      billing_details: { name, email },
    })

    if (payload.error) {
      setError(payload.error)
      console.log("payload.error", payload.error)
    } else {
      setPaymentMethod(payload.paymentMethod)
      //   console.log("payload", payload)
      //   console.log("{name,email,password}", { name, email, password })
    }

    // Check if user creating a new account already has an email
    // const checkIfUserExistsCallable = await firebase.functions.httpsCallable(
    //   "checkIfUserExists"
    // )
    // const userExists = await checkIfUserExistsCallable({
    //   userId: email,
    // })

    // if (userExists) {
    //   toast.error("User with this email already exists!")
    //   return
    // }

    try {
      // Create Subscription on the Server
      const createSubscriptionCallable = await firebase
        .functions()
        .httpsCallable("createSubscription")

      const subscription = await createSubscriptionCallable({
        userId: email,
        name: name,
        plan: process.env.GATSBY_PUBLIC_STRIPE_BERSERKSEO,
        // plan: "price_1KZpk3J1tYytnqOapiLVQpMx", // test
        // plan: process.env.GATSBY_PUBLIC_STRIPE_TEST, // test
        //   paymentMethod: wallet?.[0]?.id
        paymentMethod: payload.paymentMethod.id,
      })

      const { latest_invoice } = subscription.data

      // console.log("subscription.data", subscription.data)
      // console.log("latest_invoice", latest_invoice)

      // At this point, if there was an error with the initial invoice/payment intent,
      // then no updates to firestore would have taken place.
      // The below error handling is one way we can let the user know
      // the reason as to why their payment did not go through.
      if (latest_invoice.payment_intent) {
        const { client_secret, status } = latest_invoice.payment_intent
        // stripe will attempt to make a charge
        if (status === "requires_action") {
          const { error: confirmationError } = await stripe.confirmCardPayment(
            client_secret
          )
          if (confirmationError) {
            // console.error(confirmationError)
            alert("unable to confirm card")
            return
          }
        }

        /*
         * If we only want 1 subscription active at a time, then also cancel current subscription *
         */
        // if (subscriptions.length > 0) {
        //   cancelSubscription(subscriptions?.[0]?.id)
        // }

        // success
        //   alert('You are subscribed!');
        // toast.success("You are subscribed!")
        // getSubscriptions()

        identity
          .signup(email, password)
          .then(response => {
            console.log("response", response)
            toast.success("You are subscribed!")
            // toast.success('Something went wrong when creating your account.',error)
          })
          .catch(error => {
            toast.error(
              "Something went wrong when creating your account.",
              error
            )
          })

        const user = await firebase
          .auth()
          .createUserWithEmailAndPassword(email, password)

        if (!user) {
          toast.error(
            "Something went wrong when creating your account. Rest assured, you did not get charged."
          )
          return
        } else {
          const profile = firebase.auth().currentUser
          await profile.updateProfile({
            displayName: name,
          })
        }

        // change stat
        setPaymentComplete(true)
        setProcessing(false)
      }
    } catch (err) {
      setProcessing(false)
      // console.log("err", err)
      toast.error(`unsuccessful: ${err}`)
    }
  }

  return (
    // <form onSubmit={handleSubmit} className="register__card-container">
    <div className="register__card-container">
      <div className="register__card-heading">Add Card Details</div>
      <div className="register__card">
        {/* <CardElement style={{ color: "#fff" }} /> */}
        {/* <CardNumberElement */}
        <CardElement
          //  onChange={onChange}
          options={{
            iconStyle: "solid",
            style: {
              base: {
                // iconColor: "#c4f0ff",
                color: "#fff",
                fontWeight: 500,
                fontFamily: "Open Sans, Segoe UI, sans-serif",
                fontSize: "16px",
                fontSmoothing: "antialiased",
                autoFill: "#fff",
                ":-webkit-autofill": {
                  color: "#fff",
                },
                "::placeholder": {
                  color: "#fff",
                },
              },
              invalid: {
                iconColor: "#ffc7ee",
                color: "#ffc7ee",
              },
            },
          }}
        />
      </div>
      <button
        // style={{ backgroundColor: `${processing ? "#333" : "#272727"}` }}
        disabled={processing || !stripe}
        onClick={handleSubmit}
        className="register__confirm"
      >
        {processing ? "Processing" : "Confirm"}
      </button>
    </div>
  )
}

export default function DFYBasic() {
  // console.log(
  //   "🚀 ~ file: berserkseo-signup.js ~ line 143 ~ process.env.GATSBY_PUBLIC_STRIPE_TEST",
  //   process.env.GATSBY_PUBLIC_STRIPE_TEST
  // )
  // const { firebase } = useFirebaseArsenal()
  // const { user, profile } = useAuth()
  const [user, setUser] = useState(null)
  const [identity, setIdentity] = useState(null)
  const [step, setStep] = useState("one")
  const [stripeObj, setStripeObj] = useState({})
  const [paymentComplete, setPaymentComplete] = useState(false)
  const [accountDetails, setAccountDetails] = useState({
    name: "",
    email: "",
    password: "",
  })

  // console.log("stripeObj!", stripeObj)
  useEffect(() => {
    const auth = new GoTrue({
      APIUrl: "https://www.berserkers.dev/.netlify/identity",
      audience: "",
      // setCookie(optional): set to be false by default. If you wish to implement the remember me functionality, set the value to be true.
      setCookie: false,
    })
    // console.log("auth", auth);
    setIdentity(auth)

    // return () => {
    //   setIdentity(null);
    // };
  }, [])

  useEffect(() => {
    if (identity?.currentUser()?.email) {
      if (
        identity &&
        identity.currentUser &&
        identity.currentUser() &&
        identity.currentUser().email
      ) {
        const e = identity.currentUser().email
        // console.log("e", e);
        getUser(e)
      }

      async function getUser(e) {
        // console.log("e", e);
        const userRef = firebase
          .firestore()
          .collection("users")
          // .doc(identity.currentUser().email);
          .doc(e)
        const userDoc = await userRef.get()
        const userData = await userDoc.data()
        setUser(userData)
      }
    }
  }, [identity])
  // console.log("user", user);

  // useEffect(() => {
  //   if (profile?.email) {
  //     navigate("/")
  //   }
  // }, [profile])

  // console.log("firebase", firebase?.auth?.createUserWithEmailAndPassword)
  // console.log("user!", user)

  useEffect(() => {
    let stripePromise
    const getStripe = () => {
      if (!stripePromise) {
        stripePromise = loadStripe(
          "pk_test_51JPrYxJ1tYytnqOaHXCvc4aK8UD2tBQ5mLCkQnXXRrkNW25MPpANcH8gtl3fM2R3YrQXk6TNzQzdEipkYRzdGsxJ00MnD6g6Df"
        )
      }
      return stripePromise
    }

    async function handleStripe() {
      const stripe = await getStripe().catch(err => console.warn("err", err))
      if (stripe) {
        // console.log("stripe", stripe)
        setStripeObj(stripe)
      }
    }

    handleStripe()
  }, [])

  const {
    register,
    handleSubmit,
    watch,
    reset,
    getValues,
    formState: { errors },
  } = useForm()

  // async function onSubmitAccountDetails(data) {
  //   console.log(data)
  //   console.log("getValues", getValues())
  //   console.log("firebase", firebase.db)

  //   const userRef = firebase.db.collection("users").doc(data.email)
  //   console.log("userRef.get", await userRef.get())
  //   const userDoc = await userRef.get()
  //   console.log("userDoc", userDoc)
  //   if (userDoc.exists) {
  //     toast.error("User with this email already exists!")
  //     return
  //   }

  //   setAccountDetails(getValues())
  //   setStep("two")
  // }
  const onSubmitAccountDetails = async data => {
    // console.log(data)
    // console.log("getValues", getValues())
    // console.log("firebase", firebase.db)

    // const userRef = firebase.db.collection("users").doc(data.email)
    // console.log("userRef.get", await userRef.get())
    // const userDoc = await userRef.get()
    // console.log("userDoc", userDoc)
    // if (userDoc.exists) {
    //   toast.error("User with this email already exists!")
    //   return
    // }

    setAccountDetails(getValues())
    setStep("two")
  }
  // console.log("errors.name", errors.name)
  // console.log("errors.email", errors.email)
  // console.log("errors.password", errors.password)
  // console.log(
  //   "accountDetails.password === accountDetails.confirmPassword",
  //   accountDetails.password === accountDetails.confirmPassword
  // )
  // console.log("identity", identity)
  // console.log("firebase", firebase)
  // console.log("accountDetails", accountDetails)
  // console.log(
  //   "Object.keys(stripeObj).length > 0",
  //   Object.keys(stripeObj).length > 0
  // )

  return (
    <>
      <Header />
      <Helmet>
        <meta charSet="utf-8" />
        <link rel="icon" href={favicon} />
        <title>Berserk SEO Sign Up</title>
      </Helmet>
      <div className="package">
        <>
          <div className="dfy-img-left">
            {/* <StaticImage src="../images/abstract_1.png" alt="" /> */}
            {/* <Img fluid={data.one.childImageSharp.fluid} /> */}
            <StaticImage src="../images/abstract_5.png" />
          </div>
          <div className="dfy-img-right">
            {/* <StaticImage src="../images/abstract_2.png" alt="" /> */}
            <StaticImage src="../images/abstract_4.png" />
            {/* <Img fluid={data.two.childImageSharp.fluid} /> */}
          </div>
        </>

        {step === "one" && !user?.email && !paymentComplete && (
          <>
            <form
              className="register"
              onSubmit={handleSubmit(onSubmitAccountDetails)}
            >
              <div className="register__header">
                <div>Step 1 of 2</div>
                <div>Create Account</div>
              </div>
              <div className="register__container">
                <label className="register__label" htmlFor="name">
                  Name
                </label>
                <input
                  className="register__input"
                  id="name"
                  type="text"
                  // placeholder="Enter your email"
                  {...register("name", {
                    required: { value: true, message: "name is required" },
                    minLength: { value: 3, message: "Min chars is 3" },
                    maxLength: {
                      value: 30,
                      message: "Max length is 30 characters",
                    },
                  })}
                />
                {errors.name && <span>{errors.name.message}</span>}
                <label className="register__label" htmlFor="email">
                  Email
                </label>
                <input
                  className="register__input"
                  id="email"
                  type="email"
                  // placeholder="Enter your email"
                  {...register("email", {
                    required: { value: true, message: "email is required" },
                    minLength: { value: 5, message: "Min chars is 5" },
                    maxLength: {
                      value: 300,
                      message: "Max length is 300 characters",
                    },
                    pattern: {
                      value: /\S+@\S+.\S+/,
                      message: "Entered value does not match email format",
                    },
                  })}
                />
                {errors.email && <span>{errors.email.message}</span>}

                <label className="register__label" htmlFor="password">
                  Password
                </label>
                <input
                  className="register__input"
                  id="password"
                  type="password"
                  // placeholder="Enter your password"
                  {...register("password", {
                    required: { value: true, message: "password is required" },
                    minLength: { value: 9, message: "Min chars is 9" },
                    maxLength: {
                      value: 25,
                      message: "Max length is 25 characters",
                    },
                  })}
                />
                {errors.password && <span>{errors.password.message}</span>}
                <label className="register__label" htmlFor="password">
                  Confirm Password
                </label>
                <input
                  className="register__input"
                  id="confirmPassword"
                  type="password"
                  // placeholder="Confirm your password"
                  {...register("confirmPassword", {
                    required: { value: true, message: "Confirm your password" },
                    minLength: { value: 9, message: "Min chars is 9" },
                    maxLength: {
                      value: 25,
                      message: "Max length is 25 characters",
                    },
                    validate: value =>
                      value === watch("password") || "Passwords don't match.",
                  })}
                />
                {errors.confirmPassword && (
                  <span>{errors.confirmPassword.message}</span>
                )}

                <button
                  type="submit"
                  className="register__confirm"
                  // onClick={() => setStep("two")}
                >
                  Next Step
                </button>
              </div>
            </form>
          </>
        )}

        {/* {step === "two" && ( */}
        {(step === "two" || user?.email) && !paymentComplete && (
          <>
            <form
              className="register"
              onSubmit={handleSubmit(onSubmitAccountDetails)}
            >
              <div className="register__header">
                {user?.activePlans?.length > 0 ? (
                  <div
                    style={{
                      width: "100%",
                      margin: "0 auto",
                      textAlign: "center",
                    }}
                  >
                    Already Purchased
                  </div>
                ) : (
                  <>
                    <div
                      onClick={() => setStep("one")}
                      className="register__back-arrow"
                    >
                      <svg
                        data-name="Layer 1"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 416 416"
                      >
                        <path d="M208 0C93.13 0 0 93.13 0 208s93.13 208 208 208 208-93.13 208-208S322.87 0 208 0zm35.31 292.69a16.05 16.05 0 015 11.4 16.23 16.23 0 01-1.18 6.23 16.06 16.06 0 01-8.79 8.79 16 16 0 01-17.63-3.8l-96-96a16 16 0 010-22.62l96-96a16 16 0 0122.62 22.62L158.65 208z" />
                      </svg>
                    </div>
                    <div>Payment Details</div>
                  </>
                )}
              </div>
              <div className="register__top-content">
                <div className="register__text">Total price per month</div>
                <div className="register__price">
                  <svg
                    id="price"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 553.26 258.93"
                  >
                    <path
                      // fill="#272727"
                      fill="#fff"
                      className="cls-1"
                      d="M190.6 467.43c0-19.21-14-27.2-29.57-33l-11-4.32c-10.14-3.67-13.81-8.63-13.81-14.46 0-7.34 6-12.08 14.68-12.08 7.77 0 14 3.88 17.26 12.94l18.78-5c-3.24-13.81-13.81-23.52-28.49-26.11v-19.19h-15.76V385c-16.84 2.59-29.78 13.81-29.78 30.43 0 13.16 7.76 24.6 26.32 31.72l12.09 4.53c10.79 4.32 15.54 8.63 15.54 15.76 0 9.92-8.63 14.45-17.48 14.45-11.66 0-18.78-7.55-21.15-17l-19.42 5.39c3 17.7 16 28.27 33.88 30.43v18.78h15.75v-19c17.91-2.64 32.16-13.64 32.16-33.06zM232.56 519.44h24V366.21H209v22.23h23.52zM387.22 461.81H371v-95.6h-24.35L281 461.81v20.94h65.61v36.69H371v-36.69h16.19zm-82 0l41.44-62.36v62.36zM454.42 364.05c-27.19 0-51.15 18.78-51.15 49 0 28.27 20.93 47 45.75 47a44.36 44.36 0 0013.17-1.94L418 519.44h27.19l36.69-51.37c9.49-12.73 23.09-30.21 23.09-54.17-.05-31.07-23.36-49.85-50.55-49.85zm-.22 75.75c-16 0-28.05-11.22-28.05-27.41 0-16 12.08-27.4 28.05-27.4s28.06 11.43 28.06 27.4c0 16.19-12.09 27.41-28.06 27.41zM543.85 435a6.22 6.22 0 006.39-6.31 6.34 6.34 0 10-6.39 6.31zM580.81 364.05c-12.38 0-23.28 8.45-23.28 22.05 0 12.73 9.53 21.18 20.83 21.18a20.1 20.1 0 006-.88L564.21 434h12.38l16.7-23.12c4.32-5.73 10.51-13.6 10.51-24.38 0-14-10.61-22.45-22.99-22.45zm-.1 34.1c-7.27 0-12.77-5-12.77-12.34s5.5-12.34 12.77-12.34 12.77 5.15 12.77 12.34-5.48 12.34-12.77 12.34zM634.82 364.05c-12.38 0-23.28 8.45-23.28 22.05 0 12.73 9.53 21.18 20.82 21.18a20.12 20.12 0 006-.88L618.22 434h12.38l16.69-23.12c4.33-5.73 10.51-13.6 10.51-24.38 0-14-10.6-22.45-22.98-22.45zm-.1 34.1c-7.27 0-12.77-5-12.77-12.34s5.5-12.34 12.77-12.34 12.77 5.15 12.77 12.34-5.49 12.34-12.77 12.34z"
                      transform="translate(-108.81 -260.5)"
                    />
                    <path
                      stroke="#fff"
                      strokeWidth="7"
                      // fill="#272727"
                      fill="#fff"
                      d="M314.14 27.41h239.12"
                    />
                    <path
                      fill="#fff"
                      className="cls-1"
                      d="M609.48 290.11a2.18 2.18 0 002.24-2.21 2.22 2.22 0 10-2.24 2.21zM471.11 297.12c0-6.72-4.91-9.52-10.35-11.56l-3.85-1.51c-3.55-1.28-4.83-3-4.83-5.06 0-2.57 2.11-4.23 5.13-4.23 2.72 0 4.91 1.36 6.05 4.53l6.57-1.73a11.94 11.94 0 00-10-9.14v-6.73h-5.52v6.58c-5.89.9-10.42 4.83-10.42 10.65 0 4.6 2.72 8.61 9.21 11.1l4.23 1.59c3.78 1.51 5.44 3 5.44 5.51 0 3.47-3 5.06-6.12 5.06a7.38 7.38 0 01-7.4-6l-6.8 1.89c1.06 6.2 5.59 9.9 11.86 10.65v6.57h5.52v-6.64c6.3-.88 11.28-4.73 11.28-11.53zM476.06 315.32h35v-7.47h-20.2L502 293.72c4.23-5.36 9-10.57 9-17.9 0-8.46-6.5-14.88-16.92-14.88-11.26 0-17.37 7.33-17.37 16.31v2.12h8.45V278c0-6.65 4.54-9.22 8.77-9.22 4 0 8.23 2.12 8.23 8.16 0 5-2.95 8.31-8.91 16.09zM554.61 295.16h-5.67v-33.47h-8.53l-23 33.47v7.32h23v12.84h8.53v-12.84h5.67zm-28.71 0l14.51-21.83v21.83zM578.13 260.94c-9.52 0-17.9 6.57-17.9 17.15 0 9.89 7.32 16.46 16 16.46a15.31 15.31 0 004.61-.68l-15.49 21.45h9.52l12.84-18c3.32-4.46 8.08-10.58 8.08-19 .01-10.81-8.15-17.38-17.66-17.38zm-.08 26.51c-5.59 0-9.82-3.93-9.82-9.59a9.82 9.82 0 0119.64 0c0 5.66-4.23 9.59-9.82 9.59zM622.42 260.5c-4.33 0-8.15 3-8.15 7.72a7.17 7.17 0 007.29 7.41 7 7 0 002.1-.31l-7 9.66h4.33l5.84-8.09c1.52-2 3.68-4.76 3.68-8.53-.05-4.9-3.76-7.86-8.09-7.86zm0 11.94a4.32 4.32 0 114.47-4.32 4.25 4.25 0 01-4.51 4.32zM641.32 260.5c-4.33 0-8.15 3-8.15 7.72a7.18 7.18 0 007.29 7.41 6.93 6.93 0 002.1-.31l-7 9.66h4.33l5.85-8.09c1.51-2 3.68-4.76 3.68-8.53-.05-4.9-3.77-7.86-8.1-7.86zm0 11.94a4.32 4.32 0 114.47-4.32 4.25 4.25 0 01-4.5 4.32z"
                      transform="translate(-108.81 -260.5)"
                    />
                    <path
                      d="M309.19 27.41h239.09"
                      stroke="#fff"
                      strokeWidth="6"
                      // fill="#272727"
                      fill="#fff"
                    />
                  </svg>
                </div>
                <div className="register__text">
                  7-day, risk-free, money-back guarantee!
                </div>
              </div>

              {/* {stripeObj && */}
              {Object.keys(stripeObj).length > 0 &&
                firebase &&
                accountDetails.password === accountDetails.confirmPassword &&
                !errors.name &&
                !errors.email &&
                !errors.password &&
                identity && (
                  <Elements stripe={stripeObj}>
                    <StripeCard
                      firebase={firebase}
                      identity={identity}
                      password={accountDetails.password}
                      email={accountDetails.email}
                      name={accountDetails.name}
                      setPaymentComplete={setPaymentComplete}
                    />
                  </Elements>
                )}
              {/* {user?.activePlans?.length && (
                <button
                  type="submit"
                  style={{ backgroundColor: "#272727", paddingBottom: "2rem" }}
                  className="register__confirm"
                >
                  Already Purchased
                </button>
              )} */}
            </form>
          </>
        )}

        {paymentComplete && (
          <div className="register">
            <div
              style={{ justifyContent: "center", width: "100%" }}
              className="register__header"
            >
              <div>
                {/* <b>Purchase Complete</b> */}
                Purchase Complete
              </div>
              {/* <div>Create Account</div> */}
            </div>
            <div
              style={{ alignItems: "center" }}
              className="register__container"
            >
              <div className="register__purchased-header">Let's do this</div>
              <div className="register__purchased-header">
                {accountDetails?.name}
                {accountDetails?.name ? "!" : ""}
              </div>

              <div className="register__purchased-p">
                Thank you for allowing us to help you supercharge your business
                forward.
              </div>
              <div className="register__purchased-p">
                For instructions on what to do next, you can expect an email{" "}
                {accountDetails?.email ? `sent to` : ""}{" "}
                <span className="register__purchased-highlight">
                  {accountDetails?.email ? `${accountDetails.email}` : ""}
                </span>{" "}
                from{" "}
                <span className="register__purchased-highlight">
                  hello@berserkers.dev
                </span>{" "}
                within the hour. Be sure to check your spam folder, updates
                folder, promotions folder, etc.
              </div>
              <div className="register__purchased-p">
                If you don’t see anything after an hour, please reach out at{" "}
                {` `}
                <span className="register__purchased-highlight">
                  hello@berserkers.dev
                </span>{" "}
                and we’ll sort it out.
              </div>
              <div className="register__purchased-p">
                To manage your subscriptions, you can navigate to your{" "}
                <Link className="register__purchased-highlight" to="/dashboard">
                  dashboard
                </Link>
                .
              </div>
              <div className="register__purchased-p">
                For login, please enter the email and password you used during
                this registration process.
              </div>
              <div className="register__purchased-p">
                Now let's grow your business!
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  )
}
